<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col class="pa-0" cols="auto" :class="showAppBar ? 'full-screen-height-with-app-bar' : 'fill-height'">
        <ExpandableWidthDrawer border-position="right" :default-width="350" color="grey lighten-3">
          <v-card flat width="100%" class="transparent">
            <div v-if="false" class="pa-0 text-end">
              <v-btn text @click="changeShowReservationInfo" class="white" small>
                <v-icon v-if="showReservationInfo === true">
                  mdi-arrow-left
                </v-icon>
                <v-icon v-else>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
            <SimpleBookingOverview v-if="showReservationInfo" :location="location" :locations="locations"
              :calendar-events="calendarEvents" @selectBooking="selectBooking"
              @monthChanged="addCalendarEventsFromMonth" />
          </v-card>
        </ExpandableWidthDrawer>
      </v-col>
      <v-col class="pa-0">
        <v-container fluid class="pa-0">
          <v-row align="center" class="pl-2 pr-2">
            <v-col cols="12" md="3" id="calendar-date" style="font-size: 18px;">
              {{ selectedDateAsText }}
            </v-col>
            <v-col id="actions" cols="auto" md="9" class="pt-0 pb-0">
              <v-container fluid class="pa-0">
                <v-row dense align="center" justify="end">
                  <v-col cols="auto">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                          {{ $t('optimize') }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="openReminders">
                          <v-list-item-title>{{ $t('reminders') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openAdjustBookableHours">
                          <v-list-item-title>{{ $t('adjustBookableHours') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openReorderTables">
                          <v-list-item-title>{{ $t('reorderTables') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn tile depressed text @click="searchBookings">
                      {{ $t('search') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn dark tile :color="dateBlocked ? 'green lighten-1' : 'red lighten-1'" depressed
                      @click="toggleBlockBooking" text>
                      {{ dateBlocked ? $t('unblockDay') : $t('blockDay') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn dark tile :color="salesCloudColors.softBlack" depressed @click="createBooking">
                      {{ $t('addBooking') }}
                    </v-btn>
                    <v-btn tile outlined @click="createWalkInBooking">
                      {{ $t('walkIn') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <div class="text-end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-on="on" v-bind="attrs" @click="showReservationWidgets = true">
                            <v-icon>
                              mdi-application
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('reservationWidgets') }}</span>
                      </v-tooltip>
                      <v-btn icon @click="refresh" :loading="isGettingBookings" :disabled="isGettingBookings">
                        <v-icon>
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                      <v-menu v-model="showCalendarSettings" :close-on-content-click="false"
                        transition="scale-transition" origin="right" offset-x left max-width="auto" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="isGettingBookings" icon v-bind="attrs" v-on="on">
                            <v-icon>
                              mdi-cog
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-card width="500">
                          <v-card-title>
                            {{ $t('calendarSettings') }}
                          </v-card-title>
                          <v-card-text>
                            <v-container fluid class="pa-0">
                              <v-row>
                                <v-col id="calendar-view-mode" cols="12">
                                  <v-select :label="$t('viewMode')" dense outlined hide-details :items="viewModes"
                                    v-model="calendarViewMode" :disabled="isGettingBookings" />
                                </v-col>
                                <v-col id="calendar-event-items" cols="12">
                                  <v-select dense hide-details :label="$t('items')" outlined v-model="selectedItems"
                                    :items="eventItems" item-text="title" item-value="uuid" multiple
                                    :disabled="isGettingBookings" persistent-placeholder
                                    :placeholder="$t('noneSelected')">
                                    <template v-slot:prepend-item>
                                      <v-list-item @click="selectAllItems">
                                        <v-list-item-action>
                                          <v-icon v-if="selectedItems.length === eventItems.length">
                                            mdi-checkbox-marked
                                          </v-icon>
                                          <v-icon
                                            v-else-if="selectedItems.length > 0 && selectedItems.length !== eventItems.length">
                                            mdi-minus-box
                                          </v-icon>
                                          <v-icon v-else>
                                            mdi-checkbox-blank-outline
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ $t('selectAll') }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="selectedItems.length === 1">
                                        {{ item.title }}
                                      </span>
                                      <span v-else-if="selectedItems.length === eventItems.length">
                                        <span v-if="index === 0">{{ $t('all') }}</span>
                                      </span>
                                      <span v-else>
                                        <span v-if="index === 0">
                                          {{ typeof item.title === 'string' ? item.title.substring(0, 20) +
                                            (item.title.length > 20 ? '...' : '') : '' }}
                                        </span>
                                        <span v-if="index === 1" class="pl-1">
                                          {{ ` (+${selectedItems.length - 1} ${$t('more')})` }}
                                        </span>
                                      </span>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col id="calendar-reservation-zones" cols="12">
                                  <v-select :label="$t('zones')" dense outlined hide-details multiple
                                    v-model="selectedZones" :items="selectableZones" item-value="uuid" item-text="name"
                                    :disabled="isGettingBookings" persistent-placeholder
                                    :placeholder="$t('noneSelected')">
                                    <template v-slot:prepend-item>
                                      <v-list-item @click="selectAllZones">
                                        <v-list-item-action>
                                          <v-icon v-if="selectedZones.length === selectableZones.length">
                                            mdi-checkbox-marked
                                          </v-icon>
                                          <v-icon
                                            v-else-if="selectedZones.length > 0 && selectedZones.length !== selectableZones.length">
                                            mdi-minus-box
                                          </v-icon>
                                          <v-icon v-else>
                                            mdi-checkbox-blank-outline
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ $t('selectAll') }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="selectedZones.length === 1">
                                        {{ item.name }}
                                      </span>
                                      <span v-else-if="selectedZones.length === selectableZones.length">
                                        <span v-if="index === 0">{{ $t('all') }}</span>
                                      </span>
                                      <span v-else>
                                        <span v-if="index === 0">
                                          {{ typeof item.name === 'string' ? item.name.substring(0, 20) +
                                            (item.name.length > 20 ? '...' : '') : '' }}
                                        </span>
                                        <span v-if="index === 1" class="pl-1">
                                          {{ ` (+${selectedZones.length - 1} ${$t('more')})` }}
                                        </span>
                                      </span>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col id="calendar-reservation-tables" cols="12">
                                  <v-select dense hide-details :label="$t('tables/units')" outlined
                                    v-model="selectedLocationVariations" :items="selectableLocationVariations"
                                    item-text="label" item-value="uuid" multiple :disabled="isGettingBookings"
                                    persistent-placeholder :placeholder="$t('noneSelected')">
                                    <template v-slot:prepend-item>
                                      <v-list-item @click="selectAllVariations">
                                        <v-list-item-action
                                          v-if="Array.isArray(selectedLocationVariations) && Array.isArray(selectableLocationVariations)">
                                          <v-icon
                                            v-if="selectedLocationVariations.length === selectableLocationVariations.length">
                                            mdi-checkbox-marked
                                          </v-icon>
                                          <v-icon
                                            v-else-if="selectedLocationVariations.length > 0 && selectedLocationVariations.length !== selectableLocationVariations.length">
                                            mdi-minus-box
                                          </v-icon>
                                          <v-icon v-else>
                                            mdi-checkbox-blank-outline
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ $t('selectAll') }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="selectedLocationVariations.length === 1">
                                        {{ item.label }}
                                      </span>
                                      <span
                                        v-else-if="selectedLocationVariations.length === selectableLocationVariations.length">
                                        <span v-if="index === 0">{{ $t('all') }}</span>
                                      </span>
                                      <span v-else>
                                        <span v-if="index === 0">
                                          {{ typeof item.label === 'string' ? item.label.substring(0, 20) +
                                            (item.label.length > 20 ? '...' : '') : '' }}
                                        </span>
                                        <span v-if="index === 1" class="pl-1">
                                          {{ ` (+${selectedLocationVariations.length - 1} ${$t('more')})` }}
                                        </span>
                                      </span>
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col id="calendar-booking-statuses" cols="12">
                                  <v-select :label="$t('status')" dense outlined hide-details :items="bookingStatuses"
                                    v-model="selectedBookingStatus" :disabled="isGettingBookings || true" />
                                </v-col>
                                <v-col id="calendar-booking-states" cols="12">
                                  <v-select dense :label="$t('bookingState')" outlined v-model="selectedBookingStates"
                                    :items="bookingStates" item-text="text" item-value="value"
                                    :hint="$t('nothingSelectedDefaultsToActive')" persistent-hint multiple />
                                </v-col>
                                <v-col cols="12" id="calendar-show-zone">
                                  <v-switch :label="$t('showZoneColumn')" v-model="switchZoneColumn" />
                                </v-col>
                                <v-col cols="6" id="calendar-start-time">
                                  <v-select dense hide-details :label="$t('calendarStartTime')" outlined
                                    v-model="calendarSettingsStartTime" :items="calendarSettingsSelectableTimes"
                                    item-text="text" item-value="value" />
                                </v-col>
                                <v-col cols="6" id="calendar-end-time">
                                  <v-select dense hide-details :label="$t('calendarEndTime')" outlined
                                    v-model="calendarSettingsEndTime" :items="calendarSettingsSelectableTimes"
                                    item-text="text" item-value="value" />
                                </v-col>
                                <v-col id="calendar-slot-size" cols="12">
                                  <v-select dense hide-details :label="$t('slotSize')" outlined
                                    v-model="calendarSettingsSlotDuration" :items="slotSizes" item-text="text"
                                    item-value="value" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn color="error" @click="showCalendarSettings = false">
                              {{ $t('close') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row id="calendar-color-legend" class="caption pr-2 pl-2" dense>
            <template v-for="state in availableBookingStates">
              <v-col :key="state.value" cols="auto" class="pr-3">
                <v-icon small :color="state.color">
                  mdi-checkbox-blank
                </v-icon>
                {{ state.text }}
              </v-col>
            </template>
            <v-col cols="auto" class="pr-3">
              <v-icon small :color="salesCloudColors.softBlack">
                mdi-checkbox-blank
              </v-icon>
              {{ $t('redeemed') }}
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="pr-4">
              <span v-if="isGettingBookings">
                {{ $t('gettingData') }}...
              </span>
              <span v-else>
                {{ $t('dataIsFrom') }} {{ lastFetchedReadable }}
              </span>
            </v-col>
            <v-col v-if="isGettingBookings" cols="auto">
              <v-progress-circular size="12" width="2" indeterminate />
            </v-col>
          </v-row>
          <v-row class="calendar-wrapper-row" dense>
            <v-col cols="12">
              <FullCalendar :key="fullCalendarKey" ref="fullCalendarRef" :options="newCalendarOptions"
                class="calendar-events" :class="showAppBar ? 'calendar-height-with-app-bar' : 'calendar-height'" />
            </v-col>
            <v-col cols="12" v-show="false">
              <FullCalendar :key="fullCalendarKey" ref="fullCalendar" :options="calendarOptions" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-if="showReservationWidgets" v-model="showReservationWidgets" max-width="500">
      <ReservationWidgetsList />
    </v-dialog>
    <BlockedSlotDialog @close="selectedBlockedSlot = null"
      v-if="selectedBlockedSlot !== null && selectedBlockedSlot !== undefined" :blocked-slot="selectedBlockedSlot"
      @unblockSlot="unblockSlot" />
    <SlotActionDialog v-if="selectedSlot !== null && selectedSlot !== undefined" :use-block-slot="slotIsItem"
      @close="selectedSlot = null" @createBooking="createBooking" @blockSlot="openBlockSlotConfirmationDialog" />
    <v-dialog v-model="showBlockSlotConfirmationDialog" max-width="290">
      <v-sheet class="white mb-3">
        <v-card-title class="justify-center">
          {{ $t('attention!') }}
        </v-card-title>
        <v-card-text v-html="dateBlockedBetweenText" />
        <v-btn min-width="145" :color="salesCloudColors.green" text @click="blockSlot">
          {{ $t('confirm') }}
        </v-btn>
        <v-btn min-width="145" :color="salesCloudColors.red" text @click="cancelBlockSlot">
          {{ $t('cancel') }}
        </v-btn>
      </v-sheet>
    </v-dialog>
    <ConfirmEventUpdateDialog v-if="showConfirmEventUpdateDialog" :processing="processing"
      @confirm="updateEvent(eventToUpdate)" @cancel="cancelEventUpdate" />
  </v-container>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"
import moment from "moment"
import SimpleBookingOverview from "@/components/booking/SimpleBookingOverview"
import BlockedSlotDialog from "@/components/booking/BlockedSlotDialog"
import SlotActionDialog from "@/components/booking/SlotActionDialog"
import ConfirmEventUpdateDialog from "@/components/booking/ConfirmEventUpdateDialog"
import ExpandableWidthDrawer from "@/components/common/ExpandableWidthDrawer"
import ReservationWidgetsList from "@/components/booking/reservationWidget/ReservationWidgetsList"

export default {
  name: "BookingNewCalendar",
  components: {
    ReservationWidgetsList,
    ExpandableWidthDrawer,
    ConfirmEventUpdateDialog,
    SlotActionDialog,
    BlockedSlotDialog,
    //AddBookingsDialog,
    SimpleBookingOverview,
    FullCalendar
  },
  props: {
    bookings: {
      type: Array,
      default: () => []
    },
    location: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showBlockSlotConfirmationDialog: false,
      lastRefreshed: new Date(),
      refreshing: false,
      calendarEvents: [],
      checkBookingsIntervalInMilliseconds: 300000, // 300000 ms = 5 minutes
      blockedDates: [],
      calendarViewMode: null,
      selectedBookingStatus: null,
      selectedZones: [],
      showCalendarSettings: false,
      dotSymbol: '·',
      showReservationInfo: true,
      selectDate: false,
      selectDate2: null,
      fullCalendarKey: 0,
      showBookingDialog: false,
      selectedBooking: null,
      bookingItem: null,
      selectedSlot: null,
      bookingTime: null,
      bookingDuration: null,
      showSelectLocationDialog: false,
      selectedItems: [],
      selectedBookingStates: [],
      filteredBookings: null,
      selectedBlockedSlot: null,
      showConfirmEventUpdateDialog: false,
      eventToUpdate: null,
      processing: false,
      bookingObject: null,
      viewAllSettings: false,
      selectedLocationVariations: [],
      locationVariations: [],
      selectedSlotSize: '00:15:00',
      walkInBooking: false,
      showReservationWidgets: false,
      slotSizes: [
        {
          value: '00:05:00',
          text: this.$t('5min')
        },
        {
          value: '00:10:00',
          text: this.$t('10min')
        },
        {
          value: '00:15:00',
          text: this.$t('15min')
        },
        {
          value: '00:20:00',
          text: this.$t('20min')
        },
        {
          value: '00:30:00',
          text: this.$t('30min')
        },
        {
          value: '01:00:00',
          text: this.$t('60min')
        },
      ],
      newCalendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        initialView: 'resourceTimelineDay',
        headerToolbar: false,
        slotLabelInterval: "01:00",
        slotLabelFormat: [
          {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }
        ],
        slotDuration: '00:15:00',
        slotMinTime: '11:00:00',
        slotMaxTime: '23:00:00',
        resourceAreaWidth: '15%',
        selectable: true,
        editable: true,
        nowIndicator: true,
        resourceOrder: 'group,zone,sorting_weight,title',
        // eventOrder: "[-duration, order]",
        resourceAreaColumns: [
          {
            field: 'unit',
            headerContent: this.$t('tables/events')
          }
        ],
        resources: [],
        events: []
      },
      calendarOptions: {
        slotLabelFormat: { hour: 'numeric', minute: '2-digit', hour12: false },
        aspectRatio: 1,
        selectable: true,
        eventMinWidth: '5',
        // slotMinWidth: '8',
        scrollTime: '10:00',
        resourceAreaWidth: '150px',
        slotDuration: '00:15:00',
        slotMaxTime: '23:59:59',
        resourceOrder: 'weight',
        nowIndicator: true,
        resourcesInitiallyExpanded: true,
        plugins: [dayGridPlugin, interactionPlugin, resourceTimelinePlugin],
        initialView: 'resourceTimelineDay',
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        editable: true,
        eventOrder: "-duration, order",
        headerToolbar: false,
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: this.$t('unit')
          }
        ],
        resources: [],
        events: []
      }
    }
  },
  computed: {
    slotIsItem() {
      return !(typeof this.selectedItem === 'undefined' || this.selectedItem === null)
    },
    selectedItem() {
      if (this.selectedSlot === 'undefined' || this.selectedSlot === null) {
        return null
      }
      return this.$store.state.items.find(item => item.uuid === this.selectedSlot.resource._resource.extendedProps.itemUUID)
    },
    dateBlockedBetweenText() {
      let startTime = ''
      let endTime = ''
      let name = ''
      let duration = 0
      if (this.selectedSlot) {
        // TODO: This might require some retooling
        if (typeof this.selectedSlot.date !== 'undefined' && this.selectedSlot.date !== null) {
          startTime = this.selectedSlot.date.toTimeString().substring(0, 5)
          endTime = this.selectedSlot.date.toTimeString().substring(0, 5)
        }

        if (typeof this.selectedSlot.start !== 'undefined' && this.selectedSlot.start !== null) {
          startTime = this.selectedSlot.start.toTimeString().substring(0, 5)
        }
        if (typeof this.selectedSlot.end !== 'undefined' && this.selectedSlot.end !== null) {
          endTime = this.selectedSlot.end.toTimeString().substring(0, 5)
        }

        if (typeof this.selectedItem !== 'undefined' && this.selectedItem !== null) {
          name = this.selectedItem.title
          duration = this.selectedItem.durationInMinutes
        }
      }
      return `<b>` + this.$t('blockingSlot') + `: </b>` + startTime + ' - ' + endTime + `<br>
			<b>` + this.$t('event') + `:</b> ` + name + `<br>
			<b>` + this.$t('event') + ' ' + this.$t('duration') + `:</b> ` + duration + ` minutes <br>
			<br>
			<b>` + this.$t('note') + `:</b> Bookings can not be made if the <br>
			duration extends into the blocked slot.`
    },
    salesCloudColors() {
      return this.$store.state.salesCloudColors
    },
    dateBlocked() {
      if (Array.isArray(this.blockedDates)) {
        return this.blockedDates.some(d => d && d.date === this.selectedDate && d.location === this.location.uuid)
      }
      return false
    },
    lastFetchedReadable() {
      const tempDate = new Date(Number(this.lastRefreshed))
      const dateString = tempDate.toLocaleDateString()
      const timeString = tempDate.toISOString().substring(11, 19)

      return dateString + ' ' + timeString
    },
    switchZoneColumn: {
      get() {
        return !!this.newCalendarOptions.resourceAreaColumns.find(c => c.field === 'zone')
      },
      set(value) {
        if (value === false) {
          const index = this.newCalendarOptions.resourceAreaColumns.findIndex(c => c.field === 'zone')
          if (index >= 0) {
            this.newCalendarOptions.resourceAreaColumns.splice(index, 1)
            this.newCalendarOptions.resourceAreaWidth = '10%'
            localStorage.setItem(`${this.localStorageKey}:showZoneColumn`, 'false')
          }
        }

        if (value === true) {
          this.newCalendarOptions.resourceAreaColumns.unshift({
            group: true,
            field: 'zone',
            headerContent: 'Zone'
          })
          localStorage.setItem(`${this.localStorageKey}:showZoneColumn`, 'true')
          if (this.newCalendarOptions.resourceAreaColumns.length > 1) {
            this.newCalendarOptions.resourceAreaWidth = '15%'
          }
        }
      }
    },
    switchUnitColumn: {
      get() {
        return !this.newCalendarOptions.resourceAreaColumns.find(c => c.field === 'unit')
      },
      set(value) {
        const index = this.newCalendarOptions.resourceAreaColumns.findIndex(c => c.field === 'unit')

        if (value === true) {
          this.newCalendarOptions.resourceAreaColumns.splice(index, 1)
          this.newCalendarOptions.resourceAreaWidth = '8%'
          localStorage.setItem(`${this.localStorageKey}:hideUnitColumn`, 'true')
        }

        if (value === false) {
          this.newCalendarOptions.resourceAreaColumns.unshift({
            field: 'unit',
            headerContent: this.$t('unit')
          })
          localStorage.removeItem(`${this.localStorageKey}:hideUnitColumn`)
          if (this.newCalendarOptions.resourceAreaColumns.length > 1) {
            this.newCalendarOptions.resourceAreaWidth = '15%'
          }
        }
      }
    },
    calendarSettingsSlotDuration: {
      get() {
        return this.newCalendarOptions.slotDuration
      },
      set(value) {
        try {
          localStorage.setItem(`${this.localStorageKey}:slotDuration`, value)
        } catch (e) {
          //
        }

        this.newCalendarOptions.slotDuration = value
      }
    },
    calendarSettingsStartTime: {
      get() {
        return this.newCalendarOptions.slotMinTime
      },
      set(value) {
        try {
          localStorage.setItem(`${this.localStorageKey}:slotMinTime`, value)
        } catch (e) {
          //
        }

        this.newCalendarOptions.slotMinTime = value
      }
    },
    calendarSettingsEndTime: {
      get() {
        return this.newCalendarOptions.slotMaxTime
      },
      set(value) {
        try {
          localStorage.setItem(`${this.localStorageKey}:slotMaxTime`, value)
        } catch (e) {
          //
        }

        this.newCalendarOptions.slotMaxTime = value
      }
    },
    calendarSettingsSelectableTimes() {
      const times = []
      for (let timeIndex = 0; timeIndex <= 32; timeIndex++) {
        let text = `${timeIndex.toString().padStart(2, '0')}:00`
        if (timeIndex > 24) {
          const newTime = (timeIndex - 24).toString().padStart(2, '0')
          text = `${newTime}:00 (${this.$t('nextDay')})`
        }

        const value = `${timeIndex.toString().padStart(2, '0')}:00:00`
        times.push({
          text,
          value
        })
      }
      return times
    },
    showAppBar() {
      return this.$store.state.showAppBar
    },
    selectedDateAsText() {
      let selectedDate = moment().utc()
      if (typeof this.selectedDate !== 'undefined' && this.selectedDate !== null && this.selectedDate !== '') {
        selectedDate = moment(new Date(this.selectedDate)).utc()
      }
      return `${selectedDate.format('ddd')} ${this.dotSymbol} ${selectedDate.format('MMM')} ${selectedDate.format('Do')} ${this.dotSymbol} ${selectedDate.format('YYYY')}`
    },
    selectedDate: {
      set(value) {
        this.$store.commit('updateSelectedDate', value)
      },
      get() {
        return this.$store.state.selectedDate
      }
    },
    isGettingBookings() {
      return this.$store.state.isUpdatingBookings
    },
    selectedBookingState: {
      get() {
        return this.$store.state.selectedBookingState
      },
      set(value) {
        this.$store.commit('updateSelectedBookingState', value)
      }
    },
    bookingStates() {
      // const all = {
      //   value: 'null',
      //   text: 'All'
      // }
      // return [all].concat(this.$store.state.bookingStates)
      return this.$store.state.bookingStates
    },
    availableBookingStates() {
      return this.$store.state.availableBookingStates
    },
    bookingStatuses() {
      const statuses = []
      statuses.push({
        text: this.$t('all'),
        value: null
      })

      if (Array.isArray(this.availableBookingStates)) {
        for (let statusIndex = 0; statusIndex < this.availableBookingStates.length; statusIndex++) {
          const status = this.availableBookingStates[statusIndex]
          if (status) {
            statuses.push(status)
          }
        }
      }

      return statuses
    },
    organizationUUID() {
      return this.$store.state.organizationUUID
    },
    locationUUID() {
      if (this.locationPropHasValue) {
        return this.location.uuid
      }
      return null
    },
    localStorageKey() {
      return `${this.organizationUUID}:${this.locationUUID}`
    },
    calendarSettingsItemsKey() {
      return `${this.localStorageKey}:selectedItems`
    },
    calendarSettingsStatesKey() {
      return `${this.localStorageKey}:selectedBookingStates`
    },
    calendarSettingsLocationVariationsKey() {
      return `${this.localStorageKey}:selectedLocationVariations`
    },
    calendarSettingsViewModeKey() {
      return `${this.localStorageKey}:viewMode`
    },
    calendarSettingsZonesKey() {
      return `${this.localStorageKey}:selectedZones`
    },
    actualBookings() {
      if (this.bookings !== null && this.bookings !== undefined && this.bookings.length > 0) {
        return this.bookings
      }
      return []
    },
    startTimeInMillis() {
      return this.$store.state.startTimeInMillis
    },
    eventItems() {

      if (this.$store.state.items === null || this.$store.state.items === undefined) {
        return []
      }

      let eventItems = this.$store.state.items.filter(item => item.type === 'event' && item.status === true).sort((i1, i2) => i1.title.localeCompare(i2.title))
      eventItems = eventItems.filter(eventItem => eventItem.startDate === 0 || eventItem.endDate === 0 || eventItem.endDate > this.startTimeInMillis)

      if (this.location !== null && typeof this.location !== 'undefined' && this.location !== 'null' && this.location !== '') {
        const locationItems = []
        if (Array.isArray(eventItems) && eventItems.length > 0) {
          for (let itemIndex = 0; itemIndex < eventItems.length; itemIndex++) {
            const item = eventItems[itemIndex]
            if (typeof item !== 'undefined' && item !== null) {
              if (typeof item.location !== 'undefined' && item.location !== null) {
                if (item.location.uuid === this.location.uuid) {
                  locationItems.push(item)
                }
              } else {
                locationItems.push(item)
              }
            }
          }
        }
        return locationItems
      }


      return eventItems
    },
    locationVariationsList() {
      let locationVariations = []
      let index = 0
      for (let i = 0; i < this.locations.length; i++) {
        const location = this.locations[i]

        if (location.label === '' || location.label === undefined) {
          continue
        }
        if (location.variations.length === 0) {
          continue
        }

        const zones = this.getZonesInLocation(location)
        for (let z = 0; z < zones.length; z++) {
          const zone = zones[z]
          const zoneLocationVariations = this.getLocationVariationsInZone(location, zone)
          locationVariations = locationVariations.concat(zoneLocationVariations)
          index++
        }
      }
      return locationVariations
    },
    locations() {
      return this.$store.state.locations.filter(location => location.label !== '' && location.label !== null && location.active === true)
    },
    selectableLocations() {
      const allLocations = {
        label: this.$t('all'),
        uuid: 'null'
      }

      return [allLocations].concat(this.locations)
    },
    selectableZones() {
      let zones = []
      if (typeof this.location !== 'undefined' && this.location !== 'null' && this.location !== null) {
        const foundLocation = this.selectableLocations.find(l => l.uuid === this.location.uuid)
        if (foundLocation) {
          if (Array.isArray(foundLocation.variations)) {
            for (let variationIndex = 0; variationIndex < foundLocation.variations.length; variationIndex++) {
              const locationVariation = foundLocation.variations[variationIndex]
              if (typeof locationVariation !== 'undefined' && locationVariation !== null) {
                if (typeof locationVariation.zone !== 'undefined' && locationVariation.zone !== null && typeof locationVariation.zone.uuid !== 'undefined') {
                  if (!zones.some(z => z && z.uuid === locationVariation.zone.uuid)) {
                    zones.push(locationVariation.zone)
                  }
                }
              }
            }
          }
        }
      }
      return zones
    },
    bookingItems() {
      const items = []

      if (Array.isArray(this.bookings) && this.bookings.length > 0) {
        for (let i in this.bookings) {
          const booking = this.bookings[i]
          if (!booking) {
            continue
          }

          if (Array.isArray(this.selectedBookingStates) && this.selectedBookingStates.length > 0) {
            if (booking.status === '1' && this.selectedBookingStates.indexOf('active') === -1) {
              continue
            }
            if (booking.status === '0' && this.selectedBookingStates.indexOf('canceled') === -1) {
              continue
            }
          }

          let bookingResourceId = booking?.item?.uuid ?? null

          if (booking?.locationVariation?.uuid) {
            bookingResourceId = booking.locationVariation.uuid
          }

          let color = this.salesCloudColors.blue

          const isRedeemed = booking.redeemedAt ? booking.redeemedAt > 0 : false

          if (isRedeemed) {
            color = this.salesCloudColors.softBlack
          }

          if (booking.status === '0') {
            color = this.salesCloudColors.brightRed
          }

          const bookingState = Array.isArray(this.availableBookingStates) ? this.availableBookingStates.find(state => state && state.value === booking.state) : null
          if (bookingState && !isRedeemed) {
            color = bookingState.color
          }

          if (color === this.salesCloudColors.blue && booking.channel) {
            color = this.salesCloudColors.softBlue
          }

          //let bookingTitle = booking.customer.address.first_name
          let bookingTitle = ''

          try {
            bookingTitle = booking.customer.address.first_name
          } catch (e) {
            bookingTitle = this.$t('noName')
          }
          /*
          if(booking.customer.address.last_name !== null) {
            bookingTitle += ' ' + booking.customer.address.last_name.substr(0, 1)
          }
          */

          if (Array.isArray(booking.locationVariations) && booking.locationVariations.length > 0) {
            for (let x in booking.locationVariations) {
              const bookedLocationVariation = booking.locationVariations[x]

              items.push({
                id: booking.uuid,
                title: bookingTitle,
                resourceId: bookedLocationVariation.uuid,
                start: new Date(booking.startsAtTime * 1000),
                end: new Date(booking.endsAtTime * 1000),
                bookingUUID: booking.uuid,
                itemUUID: booking.item.uuid,
                color: color,
                order: booking.order,
                booking: booking
              })
            }
            continue
          }

          items.push({
            id: booking.uuid,
            title: bookingTitle,
            resourceId: bookingResourceId,
            start: new Date(booking.startsAtTime * 1000),
            end: new Date(booking.endsAtTime * 1000),
            bookingUUID: booking.uuid,
            booking: booking,
            itemUUID: booking?.item?.uuid ?? null,
            color: color,
            order: booking.order
          })
        }
      }

      if (this.blockedTimeSlots !== null && this.blockedTimeSlots !== undefined && this.blockedTimeSlots.length > 0) {
        for (let i in this.blockedTimeSlots) {
          const blockedTimeSlot = this.blockedTimeSlots[i]

          if (blockedTimeSlot === null || blockedTimeSlot === undefined) {
            continue
          }

          if (typeof this.location !== 'undefined' && this.location !== null) {

            if (!blockedTimeSlot.uuid.includes(this.location.uuid)) {
              continue
            }

          }

          items.push({
            resourceId: blockedTimeSlot.item,
            start: new Date(blockedTimeSlot.startTimeInMillis),
            end: new Date(blockedTimeSlot.endTimeInMillis),
            rendering: 'background',
            color: this.salesCloudColors.brightRed,
            blockedTimeSlot: blockedTimeSlot,
            title: this.$t('blocked')
          })
        }
      }

      return items
    },
    resources() {
      const resources = []
      let index = 0

      for (let i in this.eventItems) {
        const eventItem = this.eventItems[i]

        if (this.calendarViewMode === 'tables') {
          continue
        }

        if (Array.isArray(this.selectedItems) && !this.selectedItems.includes(eventItem.uuid)) {
          continue
        }

        if (eventItem.endDate !== null
          && eventItem.endDate !== undefined
          && eventItem.endDate > 0
          && eventItem.endDate < this.startTimeInMillis
        ) {
          continue
        }

        const bookingPaxCount = this.paxInItem(eventItem.uuid)

        resources.push({
          id: eventItem.uuid,
          title: eventItem.title + ' (' + bookingPaxCount + ' PAX)',
          weight: index,
          itemUUID: eventItem.uuid
        })

        index++
      }

      for (let i in this.locations) {

        const location = this.locations[i]

        if (this.calendarViewMode === 'events') {
          continue
        }

        if (location.label === '' || location.label === undefined) {
          continue
        }

        if (location.variations.length === 0) {
          continue
        }

        const zones = this.getZonesInLocation(location)

        for (let z in zones) {
          const zone = zones[z]

          if (typeof zone === 'undefined' || zone === null) {
            continue
          }

          // If no zones are selected we assume it like all zones being selected
          if (Array.isArray(this.selectedZones) && this.selectedZones.length > 0 && !this.selectedZones.includes(zone.uuid)) {
            continue
          }

          let locationVariations = this.getLocationVariationsInZone(location, zone)
          if (Array.isArray(this.selectedLocationVariations) && this.selectedLocationVariations.length > 0) {
            locationVariations = locationVariations.filter(locVar => {
              return this.selectedLocationVariations.includes(locVar.id)
            })
          }

          resources.push({
            id: zone.uuid,
            title: zone.name,
            children: locationVariations,
            weight: index,
            zoneUUID: zone.uuid,
          })
          index++
        }

      }

      return resources
    },
    calendarResources() {
      const resources = []
      let index = 0

      if (typeof this.calendarViewMode === 'undefined' || this.calendarViewMode === null || this.calendarViewMode === 'events') {
        if (Array.isArray(this.eventItems) && this.eventItems.length > 0) {
          for (let itemIndex = 0; itemIndex < this.eventItems.length; itemIndex++) {
            const eventItem = this.eventItems[itemIndex]

            if (Array.isArray(this.selectedItems) && !this.selectedItems.includes(eventItem.uuid)) {
              continue
            }

            resources.push({
              id: eventItem.uuid,
              zone: 'Events',
              unit: eventItem.title,
              uuid: eventItem.uuid,
              weight: index,
              group: 'a',
              itemUUID: eventItem.uuid
            })
            index++
          }
        }
      }

      if (typeof this.calendarViewMode === 'undefined' || this.calendarViewMode === null || this.calendarViewMode === 'tables') {
        if (typeof this.location !== 'undefined' && this.location !== null && Object.keys(this.location).length > 0) {
          if (Array.isArray(this.location.variations) && this.location.variations.length > 0) {
            for (let variationIndex = 0; variationIndex < this.location.variations.length; variationIndex++) {
              const variation = this.location.variations[variationIndex]

              if (typeof variation !== 'undefined' && variation !== null) {
                // Not show inactive tables
                if (variation.status === false) {
                  continue
                }

                let zone = null
                let zoneUUID = null
                if (typeof variation.zone !== 'undefined' && variation.zone !== null && typeof variation.zone.uuid !== 'undefined') {
                  zone = variation.zone.name
                  zoneUUID = variation.zone.uuid
                }

                if (Array.isArray(this.selectedZones) && !this.selectedZones.includes(zoneUUID)) {
                  continue
                }

                if (Array.isArray(this.selectedLocationVariations) && !this.selectedLocationVariations.includes(variation.uuid)) {
                  continue
                }

                resources.push({
                  id: variation.uuid,
                  unit: variation.label,
                  maximumPersons: variation.maximumPersons,
                  minimumPersons: variation.minimumPersons,
                  standardPersons: variation.standardPersons,
                  zone,
                  variationUUID: variation.uuid,
                  uuid: variation.uuid,
                  zoneUUID: zoneUUID,
                  group: 'b',
                  weight: index,
                  sorting_weight: variation.sorting_weight
                })
              }
              index++
            }
          }
        }
      }
      return resources
    },
    locationZones() {
      const locationZones = []
      if (this.locationPropHasValue) {
        if (Array.isArray(this.location.variations) && this.location.variations.length > 0) {
          for (let variationIndex = 0; variationIndex < this.location.variations.length; variationIndex++) {
            const variation = this.location.variations[variationIndex]
            if (typeof variation !== 'undefined' && variation !== null) {
              if (typeof variation.zone !== 'undefined' && variation.zone !== null && typeof variation.zone.uuid !== 'undefined') {
                locationZones.push(variation.zone)
              }
            }
          }
        }
      }
      return locationZones
    },
    locationPropHasValue() {
      return typeof this.location !== 'undefined' && this.location !== null && Object.keys(this.location).length > 0
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi()
    },
    calendarApi2() {
      return this.$refs.fullCalendarRef.getApi()
    },
    blockedTimeSlots() {
      return this.$store.state.blockedTimeSlots
    },
    selectedAppBarTab() {
      return this.$store.state.selectedAppBarTab
    },
    viewModes() {
      const modes = []

      modes.push({
        text: this.$t('all'),
        value: null
      })

      modes.push({
        text: this.$t('reservations'),
        value: 'tables'
      })

      modes.push({
        text: this.$t('events'),
        value: 'events'
      })

      return modes
    },
    allZones() {
      const zones = []
      if (Array.isArray(this.locations) && this.locations.length > 0) {
        for (let locationIndex = 0; locationIndex < this.locations.length; locationIndex++) {
          const location = this.locations[locationIndex]

          if (typeof location.label === 'undefined' || location.label === null || location.label === '') {
            continue
          }

          if (!Array.isArray(location.variations) || location.variations.length <= 0) {
            continue
          }

          const variations = location.variations
          for (let variationIndex = 0; variationIndex < variations.length; variationIndex++) {
            const unit = location.variations[variationIndex]

            if (typeof unit === 'undefined' || unit === null || typeof unit.zone === 'undefined' || unit.zone === null) {
              continue
            }

            if (zones.some(zone => zone && zone.uuid === unit.zone.uuid)) {
              continue
            }

            zones.push(unit.zone)
          }
        }
      }
      return zones
    },
    selectableLocationVariations() {
      const variations = []

      if (Array.isArray(this.locations) && this.locations.length > 0) {
        for (let locationIndex = 0; locationIndex < this.locations.length; locationIndex++) {
          const location = this.locations[locationIndex]
          if (typeof location === 'undefined' || location === null) {
            continue
          }

          if (typeof this.location !== 'undefined' && this.location !== null && this.location !== '' && this.location !== 'null') {
            if (this.location.uuid !== location.uuid) {
              continue
            }
          }

          if (Array.isArray(location.variations) && location.variations.length > 0) {
            for (let variationIndex = 0; variationIndex < location.variations.length; variationIndex++) {
              const variation = location.variations[variationIndex]
              if (typeof variation === 'undefined' || variation === null) {
                continue
              }

              if (Array.isArray(this.selectedZones) && this.selectedZones.length > 0) {
                if (typeof variation.zone !== 'undefined' && variation.zone !== null && typeof variation.zone.uuid !== 'undefined' && variation.zone.uuid !== null) {
                  if (!this.selectedZones.includes(variation.zone.uuid)) {
                    continue
                  }
                }
              }

              if (!variations.some(v => v.uuid === variation.uuid)) {
                variations.push(variation)
              }
            }
          }
        }
      }

      return variations
    },
    genericDialog() {
      return this.$store.state.genericDialog
    }
  },
  methods: {
    cancelBlockSlot() {
      this.selectedBooking = null
      this.showBlockSlotConfirmationDialog = false
    },
    openBlockSlotConfirmationDialog() {
      this.showBlockSlotConfirmationDialog = true
    },
    openReminders() {
      this.$store.commit('updateGenericDialog', {
        title: 'BookingReminders',
        component: 'components/booking/BookingReminders'
      })
    },
    openAdjustBookableHours() {
      this.$store.commit('updateGenericDialog', {
        title: 'Adjust Bookable Hours',
        component: 'components/booking/AdjustBookableHours'
      })
    },
    openReorderTables() {
      this.$store.commit('updateGenericDialog', {
        title: 'Reorder Tables',
        component: 'components/booking/ReorderTables'
      })
    },
    openBookingDialog() {
      this.$store.commit('updateGenericDialog', {
        'component': 'components/booking/AddBookingCard',
        'props': {
          'bookingObject': this.selectedBooking,
          'bookingItem': this.bookingItem,
          'bookingTime': this.bookingTime,
          'bookingDuration': this.bookingDuration,
          'walkIn': this.walkInBooking,
          'calendarLocationVariations': this.locationVariations,
        }
      })
    },
    optimize() {

    },
    searchBookings() {
      this.$store.commit('updateGenericDialog', {
        title: 'Generic Dialog',
        component: 'components/booking/BookingSearch'
      })
    },
    fetchBookingsInterval() {
      setTimeout(() => {
        this.getBookingsByDate(this.selectedDate)
        this.getBookingsPerDay(this.selectedDate)
        this.fetchBookingsInterval()
      }, this.checkBookingsIntervalInMilliseconds)
    },
    selectBooking(booking) {
      this.selectedBooking = booking
    },
    getLocalStorageData() {
      try {
        const slotDuration = localStorage.getItem(`${this.localStorageKey}:slotDuration`)
        if (slotDuration) {
          this.calendarSettingsSlotDuration = slotDuration
        }

        const slotMinTime = localStorage.getItem(`${this.localStorageKey}:slotMinTime`)
        if (slotMinTime) {
          this.calendarSettingsStartTime = slotMinTime
        }

        const slotMaxTime = localStorage.getItem(`${this.localStorageKey}:slotMaxTime`)
        if (slotMaxTime) {
          this.calendarSettingsEndTime = slotMaxTime
        }

        const showZoneColumn = localStorage.getItem(`${this.localStorageKey}:showZoneColumn`)
        if (showZoneColumn) {
          this.switchZoneColumn = showZoneColumn === 'true'
        }

        const hideUnitColumn = localStorage.getItem(`${this.localStorageKey}:hideUnitColumn`)
        if (hideUnitColumn) {
          this.switchUnitColumn = hideUnitColumn === 'true'
        }

        const calendarSettingsViewMode = localStorage.getItem(this.calendarSettingsViewModeKey)
        this.calendarViewMode = calendarSettingsViewMode === 'null' ? null : calendarSettingsViewMode

        const calendarSettingsItems = localStorage.getItem(this.calendarSettingsItemsKey)
        if (calendarSettingsItems) {
          try {
            this.selectedItems = JSON.parse(calendarSettingsItems)
          } catch (e) {
            this.selectedItems = []
          }
        }

        const calendarSettingsStates = localStorage.getItem(this.calendarSettingsStatesKey)
        if (calendarSettingsStates) {
          try {
            this.selectedBookingStates = JSON.parse(calendarSettingsStates)
          } catch (e) {
            this.selectedBookingStates = ['active']
          }
        }

        const calendarSettingsZones = localStorage.getItem(this.calendarSettingsZonesKey)
        if (calendarSettingsZones) {
          try {
            this.selectedZones = JSON.parse(calendarSettingsZones)
          } catch (e) {
            this.selectedZones = []
          }
        }

        const calendarSettingsLocationVariations = localStorage.getItem(this.calendarSettingsLocationVariationsKey)
        if (calendarSettingsLocationVariations) {
          try {
            this.selectedLocationVariations = JSON.parse(calendarSettingsLocationVariations)
          } catch (e) {
            this.selectedLocationVariations = []
          }
        }

      } catch (e) {
        //
      }
    },
    refresh() {
      this.getBookingsByDate(this.selectedDate)
      this.getBookingsPerDay(this.selectedDate)
      this.lastRefreshed = new Date()
    },
    getBookingsByDate(dateValue) {

      const dateMoment = moment(dateValue)

      const payload = {
        startTimeInMillis: dateMoment.startOf('day').valueOf(),
        endTimeInMillis: dateMoment.endOf('day').valueOf()
      }

      return this.$store.dispatch('getBookingsByDate', payload)
    },
    selectAllZones() {
      if (this.selectedZones.length === this.selectableZones.length) {
        this.selectedZones = []
        return
      }

      if (this.selectedZones.length < this.selectableZones.length) {
        this.selectedZones = this.selectableZones.map(zone => zone && zone.uuid)
        return
      }
    },
    selectAllVariations() {
      if (this.selectedLocationVariations.length === this.selectableLocationVariations.length) {
        this.selectedLocationVariations = []
        return
      }

      if (this.selectedLocationVariations.length < this.selectableLocationVariations.length) {
        this.selectedLocationVariations = this.selectableLocationVariations.map(variation => variation && variation.uuid)
        return
      }
    },
    selectAllItems() {
      if (this.selectedItems.length === this.eventItems.length) {
        this.selectedItems = []
        return
      }

      if (this.selectedItems.length < this.eventItems.length) {
        this.selectedItems = this.eventItems.map(item => item && item.uuid)
        return
      }
    },
    slotLabelContent(event) {
      return {
        html: '<span style="display: block;">' + event.text + '</span>'
      }
    },
    eventContent(event) {

      const eventInfo = event.event._def
      const bookingInfo = eventInfo.extendedProps.booking

      let icons = ''

      let eventContentHtml = {
        html: '<div class="fc-event-title">' + eventInfo.title + '</div>'
      }
      if (bookingInfo) {
        // Icon for ONLY external note on booking
        if (bookingInfo.externalNote && !bookingInfo.internalNote) {
          icons = '<i aria-hidden="true" file="v-icon/usage" class="v-icon notranslate mdi mdi-comment-text-outline theme--dark" style="font-size: 12px;"></i>'
        }

        // Icon for ONLY internal note on booking
        if (bookingInfo.internalNote && !bookingInfo.externalNote) {
          icons = '<i aria-hidden="true" file="v-icon/usage" class="v-icon notranslate mdi mdi-comment-outline theme--dark" style="font-size: 12px;"></i>'
        }

        // Icon for both external and internal notes on booking
        if (bookingInfo.internalNote && bookingInfo.externalNote) {
          icons = '<i aria-hidden="true" file="v-icon/usage" class="v-icon notranslate mdi mdi-comment-text-multiple-outline theme--dark" style="font-size: 12px;"></i>'
        }

        eventContentHtml.html = `<div class="fc-event-title">
            <span style="font-size: 14px; font-weight: 600; padding: 0 8px 0 4px; border-right: 1px solid white;">${bookingInfo.quantity}</span>
            <span style="padding: 0 4px; font-size: 12px;">${eventInfo.title}</span>
            <span>${icons}</span>
        </div>`
      }

      return eventContentHtml
      // return { html: '<div class="pl-1 pr-1"><div class="text-subtitle-2 pt-1" style="line-height: normal"><span>' + event.event._def.title + '</span></div><div class="caption pb-2" style="line-height: normal">' + event.event._def.extendedProps.booking.quantity + ' guests ' + icons + '</div><div' }
    },
    resourceLabelContent(resource) {
      // Resource label content is used to change what the content in the resource label looks like

      // Location variation standardPersons display
      let resourceLocationVariationInfo = ''
      if (resource && resource.resource && resource.resource.extendedProps && resource.resource.extendedProps.standardPersons) {

        let standard = resource.resource.extendedProps.standardPersons
        let min = resource.resource.extendedProps.minimumPersons
        let max = resource.resource.extendedProps.maximumPersons

        let paxText = standard

        if (min !== null && min !== undefined) {
          paxText = min
        }

        if (max !== null && max !== undefined) {
          paxText = paxText + ' - ' + max
        }

        resourceLocationVariationInfo += '<div style="font-weight: 300; font-size: 12px; margin-top: -4px;">' +
          paxText +
          ' pax' +
          '</div>'
      }

      // Event count info (currently booked / maximum grouped)
      let eventBookedInfo = ''
      if (resource && resource.resource && resource.resource.extendedProps && resource.resource.extendedProps.itemUUID && false) {
        let currentlyBooked = 0, maximumBooked = 0
        if (resource.resource.extendedProps.currentlyBookedQuantity) {
          currentlyBooked = resource.resource.extendedProps.currentlyBookedQuantity
        }
        if (resource.resource.extendedProps.maximumGroupedQuantity) {
          maximumBooked = resource.resource.extendedProps.maximumGroupedQuantity
        }

        if (currentlyBooked || maximumBooked) {
          eventBookedInfo = '<div style="' + (maximumBooked <= currentlyBooked ? 'font-weight: 600;' : 'font-weight: 300;') + (maximumBooked < currentlyBooked ? 'color: red;' : '') + 'font-size: 12px; margin-top: -4px;">' +
            currentlyBooked + ' / ' + maximumBooked + ' pax' + (maximumBooked < currentlyBooked ? ' (Overbooked)' : '') +
            '</div>'
        }
      }

      // Basic resource label
      let resourceLabel = '<div>' +
        ((resource && resource.fieldValue) ? resource.fieldValue : '') +
        (resourceLocationVariationInfo ? resourceLocationVariationInfo : eventBookedInfo) +
        '</div>'

      return { html: resourceLabel }
    },
    changeShowReservationInfo() {
      this.showReservationInfo = !this.showReservationInfo
    },
    setDateToToday() {
      this.selectedDate = moment().format("YYYY-MM-DD")
    },
    previousDay() {
      const date = new Date(this.selectedDate)
      this.selectedDate = moment(date.getTime()).subtract(1, 'day').format("YYYY-MM-DD")
    },
    nextDay() {
      const date = new Date(this.selectedDate)
      this.selectedDate = moment(date.getTime()).add(1, 'day').format("YYYY-MM-DD")
    },
    createWalkInBooking() {
      this.walkInBooking = true
      this.createBooking()
    },
    createBooking() {

      if (this.selectedSlot !== null && this.selectedSlot.resource && this.selectedSlot.resource._resource && this.selectedSlot.resource._resource.extendedProps) {

        if (typeof this.selectedSlot.resource._resource.extendedProps.itemUUID !== 'undefined') {
          this.bookingItem = this.selectedSlot.resource._resource.extendedProps.itemUUID
        }

        if (typeof this.selectedSlot.resource._resource.extendedProps.variationUUID !== 'undefined') {
          this.locationVariations = [this.selectedSlot.resource._resource.extendedProps.variationUUID]
        }

        if (this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
          this.bookingTime = this.selectedSlot.date.getTime()
        } else if (this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
          this.bookingTime = this.selectedSlot.start.getTime()
        }

        if (this.selectedSlot.end !== null && this.selectedSlot.end !== undefined) {
          this.bookingDuration = Math.round((this.selectedSlot.end.getTime() - this.bookingTime) / 60000)
        } else {
          this.bookingDuration = parseInt(this.selectedSlotSize.split(':')[1])
        }
      }

      this.selectedSlot = null

      if (this.bookingTime === null || this.bookingTime === undefined) {
        this.bookingTime = new Date(this.selectedDate).getTime()
      }
      this.showBookingDialog = true
    },
    closeBookingsDialog() {
      this.showBookingDialog = false
      this.selectedBooking = null
      this.bookingTime = null
      this.bookingDuration = null
      this.bookingItem = null
      this.walkInBooking = false
      this.locationVariations = []
    },
    rebook(booking) {
      if (!booking) {
        return
      }
      this.bookingItem = booking?.item?.uuid ?? null
      this.bookingDuration = (booking.endsAtTime - booking.startsAtTime) / 60
      this.bookingTime = booking.startsAtTime * 1000
      this.bookingObject = booking
      this.selectedBooking = null
      this.showBookingDialog = true
    },
    overwriteBooking(result) {
      this.selectedBooking = result
    },
    getBookingsPerDay(date) {
      if (typeof this.location !== 'undefined' && this.location !== null && typeof date !== 'undefined' && date !== null) {
        this.$store.dispatch('getBookingsPerDayForSelectedMonth', { selectedDate: date, locationUUID: this.location.uuid }).then(results => {
          if (Array.isArray(results) && results.length > 0) {
            this.calendarEvents = results.map(a => a.date)
          } else {
            this.calendarEvents = []
          }
        })
      }
    },
    addCalendarEventsFromMonth(date) {
      if (typeof this.location !== 'undefined' && this.location !== null && typeof date !== 'undefined' && date !== null) {
        this.$store.dispatch('getBookingsPerDayForSelectedMonth', { selectedDate: date, locationUUID: this.location.uuid }).then(results => {
          if (Array.isArray(results) && results.length > 0) {
            const newEvents = results.map(a => a.date)
            const currentEvents = Object.values(this.calendarEvents)
            for (const e in newEvents) {
              const newEvent = newEvents[e]
              if (!currentEvents.includes(newEvent)) {
                currentEvents.push(newEvent)
              }
            }
            this.calendarEvents = currentEvents
          }
        })
      }
    },
    blockSlot() {
      if (this.location === null || typeof this.location === 'undefined') {
        this.showSelectLocationDialog = true
        return null
      }

      let itemUUID = null

      if (this.selectedSlot.resource._resource && this.selectedSlot.resource._resource.extendedProps) {
        // replace .itemUUID with .uuid to allow for table blocking
        if (this.selectedSlot.resource._resource.extendedProps.itemUUID !== undefined) {
          itemUUID = this.selectedSlot.resource._resource.extendedProps.itemUUID
        }
      }

      let startTimeInMillis = 0

      if (this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
        startTimeInMillis = this.selectedSlot.date.getTime()
      } else if (this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
        startTimeInMillis = this.selectedSlot.start.getTime()
      }

      let [hours, minutes] = this.selectedSlotSize.split(":")
      hours = parseInt(hours.slice(-1)) * 60
      minutes = parseInt(minutes)

      let durationInMinutes = hours + minutes

      let endTimeInMillis = startTimeInMillis + (durationInMinutes * 60000) - 1

      if (this.selectedSlot.end !== null && this.selectedSlot.end !== undefined) {
        endTimeInMillis = this.selectedSlot.end.getTime() - 1
      }

      this.$store.dispatch('blockAvailableTimeSlot', {
        startTimeInMillis: startTimeInMillis,
        endTimeInMillis: endTimeInMillis,
        item: itemUUID,
        location: this.location.uuid
      }).finally(() => {
        this.getBookingsByDate(this.selectedDate)
      })

      this.selectedSlot = null
      this.showSelectLocationDialog = false
      this.showBlockSlotConfirmationDialog = false
    },
    unblockSlot() {
      if (this.location === null || typeof this.location === 'undefined') {
        this.showSelectLocationDialog = true
        return null
      }
      const blockedSlot = this.selectedBlockedSlot

      this.$store.dispatch('unblockSelectedTimeSlot', {
        startTimeInMillis: blockedSlot.startTimeInMillis,
        endTimeInMillis: blockedSlot.endTimeInMillis,
        item: blockedSlot.item,
        location: this.location.uuid
      }).finally(() => {
        this.getBookingsByDate(this.selectedDate)
      })

      this.selectedBlockedSlot = null
    },
    updateEvent(e) {
      this.processing = !this.processing
      const bookingUUID = e.event._def.extendedProps.bookingUUID
      const booking = this.bookings.find(booking => booking && booking.uuid === bookingUUID)
      if (!booking) {
        return
      }

      let resource

      if (e.newResource) {
        resource = e.newResource._resource
      }

      let itemUUID = booking?.item?.uuid ?? null

      if (resource?.extendedProps?.itemUUID) {
        itemUUID = resource.extendedProps.itemUUID
      }

      let locationUUID = booking?.location?.uuid ?? null
      let locationVariationUUIDs = (Array.isArray(booking.locationVariations) && booking.locationVariations.length > 0) ? booking.locationVariations.map(variation => variation && variation.uuid).filter(notNull => notNull) : null

      if (resource?.extendedProps?.locationUUID) {
        locationUUID = resource.extendedProps.locationUUID
      }

      if (resource?.extendedProps?.variationUUID) {
        locationVariationUUIDs = [resource.extendedProps.variationUUID + '']
      }

      if (locationVariationUUIDs) {
        locationVariationUUIDs = locationVariationUUIDs.map(String)
      }

      const range = e.event._instance.range
      this.$store.dispatch('updateBooking', {
        uuid: booking.uuid,
        startsAtTime: new Date(range.start).getTime() / 1000,
        endsAtTime: Math.round(new Date(range.end).getTime() / 1000),
        item: itemUUID,
        customer: booking?.customer?.uuid ?? null,
        namespace: booking.namespace,
        location: locationUUID,
        locationVariations: locationVariationUUIDs
      }).finally(() => {
        this.getBookingsByDate(this.selectedDate).then(() => {
          this.eventToUpdate = null
          this.calendarOptions.editable = true
          this.showConfirmEventUpdateDialog = false
          this.processing = !this.processing
        })
      })
    },
    cancelEventUpdate() {
      this.processing = !this.processing
      this.getBookingsByDate(this.selectedDate).then(() => {
        this.eventToUpdate = null
        this.calendarOptions.editable = true
        this.showConfirmEventUpdateDialog = false
        this.processing = !this.processing
      })
    },
    toggleAllLocationVariations() {
      if (this.selectedLocationVariations.length !== this.locationVariationsList.length) {
        this.selectedLocationVariations = this.locationVariationsList.map(locVar => locVar.id)
      } else {
        this.selectedLocationVariations = []
      }
    },
    selectSlots(e) {
      this.selectedSlot = e

      if (this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
        this.bookingTime = this.selectedSlot.date.getTime()
      } else if (this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
        this.bookingTime = this.selectedSlot.start.getTime()
        if (typeof this.selectedSlot.end !== 'undefined' && this.selectedSlot.end !== null) {
          this.bookingDuration = Math.round(this.selectedSlot.end.getTime() - this.selectedSlot.start.getTime()) / 60000
        }
      }
      if (this.selectedSlot.resource && this.selectedSlot.resource._resource && this.selectedSlot.resource._resource.extendedProps) {
        if (this.selectedSlot.resource._resource.extendedProps.itemUUID) {
          this.bookingItem = this.selectedSlot.resource._resource.extendedProps.itemUUID
        }
      }
    },
    eventDrop(e) {
      if (e.event._def.extendedProps.bookingUUID) {
        this.eventToUpdate = e
        let oldStartTime = e.oldEvent.startStr
        let oldEndTime = e.oldEvent.endStr
        let newStartTime = e.event.startStr
        let newEndTime = e.event.endStr

        if ((oldEndTime !== newEndTime) || (oldStartTime !== newStartTime)) {
          this.showConfirmEventUpdateDialog = true
          return
        }

        let newParentUUID = e.newResource._resource.parentId
        let oldParentUUID = e.oldResource._resource.parentId

        if (newParentUUID.length === 0 && oldParentUUID.length === 0) {
          this.showConfirmEventUpdateDialog = true
          return
        }

        this.calendarOptions.editable = false
        this.updateEvent(this.eventToUpdate)
      }
    },
    eventResize(e) {
      this.eventToUpdate = e
      this.showConfirmEventUpdateDialog = true
    },
    dateClick(e) {
      this.selectedSlot = e

      if (this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
        this.bookingTime = this.selectedSlot.date.getTime()
      } else if (this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
        this.bookingTime = this.selectedSlot.start.getTime()
      }
    },
    eventClick(e) {
      if (!this.calendarOptions.editable) {
        return
      }

      const bookingUUID = e.event._def.publicId

      this.selectedBooking = this.bookings.find(booking => booking && booking.uuid === bookingUUID)

      const blockedSlot = e.event._def.extendedProps.blockedTimeSlot

      if (blockedSlot) {
        this.selectedBlockedSlot = blockedSlot
      }

      if (e.event._def.extendedProps.blockedTimeSlot !== null) {
        // Do Nothing
      }
    },
    bookingsInItem(itemUUID) {

      if (this.actualBookings !== null) {
        return this.actualBookings.filter(booking => booking && booking.item && booking.item.uuid === itemUUID).length
      }

      return 0
    },
    paxInItem(itemUUID) {

      let pax = 0

      if (this.actualBookings !== null) {
        for (let i in this.actualBookings) {
          const actualBooking = this.actualBookings[i]

          if (actualBooking?.item?.uuid !== itemUUID) {
            continue
          }

          pax = pax + actualBooking.quantity
        }
      }

      return pax
    },
    getZonesInLocation(location) {
      const zones = []
      const zoneIndex = []

      if (!location || location.label === '' || location.label === undefined) {
        return zones
      }

      if (Array.isArray(location.variations) && location.variations.length === 0) {
        return zones
      }

      const variations = location.variations

      for (let n = 0; n < variations.length; n++) {
        const unit = location.variations[n]
        if (!unit?.zone?.uuid) {
          continue
        }

        if (unit.zone.uuid === '8def7e20-ce3f-4da2-97b3-3deebcded6f2') { //Sjáland Zone
          continue
        }

        if (Array.isArray(this.selectedLocationVariations) && this.selectedLocationVariations.length > 0) {
          if (!this.selectedLocationVariations.includes(unit.uuid)) {
            continue
          }
        }

        if (!zoneIndex.includes(unit.zone.uuid)) {
          zones.push(unit.zone)
          zoneIndex.push(unit.zone.uuid)
        }
      }

      return zones
    },
    getLocationVariationsInZone(location, zone) {
      const variations = location.variations.sort((v1, v2) => {

        var nameA = v1.label.toUpperCase() // ignore upper and lowercase
        var nameB = v2.label.toUpperCase() // ignore upper and lowercase

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      })
      return variations.reduce((variationsInZone, variation) => {
        if (variation?.zone?.uuid && zone?.uuid && variation.zone.uuid === zone.uuid) {
          variationsInZone.push({
            id: variation.uuid,
            title: variation.label,
            zoneUUID: zone.uuid,
            locationUUID: location.uuid,
            variationUUID: variation.uuid
          })
        }
        return variationsInZone
      }, [])
    },
    getBlockedDates() {
      if (!this.location?.uuid) {
        return []
      }
      this.$store.dispatch('bookingsGetBlockedDays', this.location.uuid).then(results => {
        this.blockedDates = results
      })
    },
    toggleBlockBooking() {
      if (!this.location?.uuid) {
        return false
      }
      const payload = {
        location: this.location.uuid,
        date: this.selectedDate
      }
      return this.$store.dispatch('toggleBlockedBooking', payload).then(result => {
        this.getBlockedDates()
        return result
      })
    },
  },
  watch: {
    selectedAppBarTab(appBarTab) {
      if (appBarTab !== 'list') {
        const _this = this
        setTimeout(
          function () {
            _this.fullCalendarKey += 1
          }, 200
        )
      }
    },
    selectedBookingStates(selectedBookingStates) {
      this.newCalendarOptions.resources = this.calendarResources
      localStorage.setItem(this.calendarSettingsStatesKey, JSON.stringify(selectedBookingStates))
      this.refresh()
    },
    selectedItems(selectedItems) {
      this.newCalendarOptions.resources = this.calendarResources
      localStorage.setItem(this.calendarSettingsItemsKey, JSON.stringify(selectedItems))
    },
    selectedZones(selectedZones) {
      this.newCalendarOptions.resources = this.calendarResources
      localStorage.setItem(this.calendarSettingsZonesKey, JSON.stringify(selectedZones))
    },
    selectedLocationVariations(selectedLocationVariations) {
      this.newCalendarOptions.resources = this.calendarResources
      localStorage.setItem(this.calendarSettingsLocationVariationsKey, JSON.stringify(selectedLocationVariations))
    },
    selectedSlotSize(value) {
      this.calendarOptions.slotDuration = value
      localStorage.setItem('slotDuration', value)
    },
    bookings(value) {
      this.newCalendarOptions.resources = this.calendarResources
      this.newCalendarOptions.events = this.bookingItems

      // Updating selectedBooking with the right value if it has changed when bookings get a new value
      if (this.selectedBooking) {
        this.selectedBooking = value.find(booking => booking && booking.uuid === this.selectedBooking.uuid)
      }
    },
    calendarViewMode(value) {
      this.newCalendarOptions.resources = this.calendarResources
      localStorage.setItem(this.calendarSettingsViewModeKey, value)
    },
    selectedDate(date) {
      this.calendarApi2.gotoDate(date)
      this.getBookingsPerDay(date)
    },
    calendarResources(value) {
      if (Array.isArray(value) && value.length > 0) {
        const eventResources = value.filter(resource => resource.group === 'a')
        const tableResources = value.filter(resource => resource.group === 'b')

        let text = this.$t('tables')

        if (tableResources.length > 0 && eventResources.length > 0) {
          text = this.$t('tables/events')
        } else if (eventResources.length > 0) {
          text = this.$t('events')
        }

        const unitColumnIndex = this.newCalendarOptions.resourceAreaColumns.findIndex(column => column && column.field === 'unit')
        if (unitColumnIndex >= 0) {
          this.newCalendarOptions.resourceAreaColumns[unitColumnIndex].headerContent = text
        }
      }
    },
    selectedBooking(newValue) {
      if (typeof newValue !== 'undefined' && newValue !== null) {
        for (const variationKey in newValue.locationVariations) {
          const locationVariation = newValue.locationVariations[variationKey]
          if (typeof locationVariation !== 'undefined' && locationVariation !== null) {
            this.locationVariations.push(locationVariation.uuid)
          }
        }
        this.bookingTime = newValue.startsAtTime
        const diff = newValue.endsAtTime - newValue.startsAtTime
        this.bookingDuration = diff / 60
        this.openBookingDialog()
      }
    },
    showBookingDialog(value) {
      if (value) {
        this.openBookingDialog()
      }
    },
    genericDialog(value) {
      if (!value) {
        this.closeBookingsDialog()
      }
    }
  },
  mounted() {
    this.getLocalStorageData()
    if (Array.isArray(this.calendarResources) && this.calendarResources.length > 0) {
      const eventResources = this.calendarResources.filter(resource => resource.group === 'a')
      const tableResources = this.calendarResources.filter(resource => resource.group === 'b')

      let text = this.$t('tables')

      if (tableResources.length > 0 && eventResources.length > 0) {
        text = this.$t('tables/events')
      } else if (eventResources.length > 0) {
        text = this.$t('events')
      }

      const unitColumnIndex = this.newCalendarOptions.resourceAreaColumns.findIndex(column => column && column.field === 'unit')
      if (unitColumnIndex >= 0) {
        this.newCalendarOptions.resourceAreaColumns[unitColumnIndex].headerContent = text
      }
    }

    this.newCalendarOptions.resources = this.calendarResources
    this.newCalendarOptions.events = this.bookingItems

    this.newCalendarOptions.dateClick = this.dateClick
    this.newCalendarOptions.eventClick = this.eventClick
    this.newCalendarOptions.eventDrop = this.eventDrop
    this.newCalendarOptions.eventResize = this.eventResize
    this.newCalendarOptions.select = this.selectSlots
    this.newCalendarOptions.eventContent = this.eventContent
    this.newCalendarOptions.slotLabelContent = this.slotLabelContent
    this.newCalendarOptions.resourceLabelContent = this.resourceLabelContent

    this.getBlockedDates()

    if (Array.isArray(this.selectedItems) && this.selectedItems.length <= 0) {
      // if getItem in localStorage return null then the key does not exist yet
      if (localStorage.getItem(this.calendarSettingsItemsKey) === null) {
        if (Array.isArray(this.eventItems) && this.eventItems.length > 0) {
          this.selectedItems = this.eventItems.map(item => item?.uuid ?? null)
        }
      }
    }

    if (Array.isArray(this.selectedZones) && this.selectedZones.length <= 0) {
      if (localStorage.getItem(this.calendarSettingsZonesKey) === null) {
        if (Array.isArray(this.selectableZones) && this.selectableZones.length > 0) {
          this.selectedZones = this.selectableZones.map(zone => zone?.uuid ?? null)
        }
      }
    }

    if (Array.isArray(this.selectedLocationVariations) && this.selectedLocationVariations.length <= 0) {
      if (localStorage.getItem(this.calendarSettingsLocationVariationsKey) === null) {
        if (Array.isArray(this.selectableLocationVariations) && this.selectableLocationVariations.length > 0) {
          this.selectedLocationVariations = this.selectableLocationVariations.map(variation => variation?.uuid ?? null)
        }
      }
    }

    // Active bookings shall always be selected by default. If the user has selected other states they are saved to local storage
    // and the below if check will not pass.
    if (Array.isArray(this.selectedBookingStates) && this.selectedBookingStates.length <= 0) {
      this.selectedBookingStates = ['active']
    }

    if (this.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223') {
      this.calendarOptions.slotMinTime = '18:00:00'
      this.calendarOptions.slotMaxTime = '26:59:59'
      this.calendarOptions.slotLabelFormat = { hour: 'numeric', minute: '2-digit', hourCycle: 'h23' }
    }

    if (this.organizationUUID === 'bb78d411-9b7c-4a51-a134-e359cc1bc7f8') {
      this.calendarOptions.slotMinTime = '12:00:00'
      this.calendarOptions.slotMaxTime = '23:59:59'
    }

    if (typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
      this.getBookingsPerDay(this.selectedDate)
    }
    this.fetchBookingsInterval()
  }
}
</script>

<style>
/*.fc-scrollgrid {*/
/*  border-top: 0 !important;*/
/*}*/

/*.fc-timeline-slot-frame {*/
/*  display: block !important;*/
/*}*/
/*a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {*/
/*  font-size: 12px;*/
/*  color: #000;*/
/*  display: block;*/
/*}*/
/*.fc-timeline-slot {*/
/*  width: 200px;*/
/*}*/
/*.fc-timeline-event {*/
/*  border-radius: 3px;*/
/*}*/
/*.fc-event-main {*/
/*  line-height: normal;*/
/*}*/

/*.calendar-events >>> .fc-event {*/
/*  height: 42px !important;*/
/*}*/

/*.selection-single-line .v-select__selections {*/
/*  flex-wrap: nowrap;*/
/*}*/

.full-screen-height {
  height: 100vh;
}

.full-screen-height-with-app-bar {
  height: calc(100vh - 112px);
}

.calendar-height {
  height: calc(100vh - 90px) !important;
}

.calendar-height-with-app-bar {
  height: calc(100vh - 90px - 112px) !important;
}

.fc-event {
  border-radius: 5px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border-left: 0 !important;
}

.col.fill-height {
  height: 100vh;
}
</style>
